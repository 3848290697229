/* 
Welcome to the bologense code
*/

import Swup from "swup";
import SwupA11yPlugin from "@swup/a11y-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupFragmentPlugin from "@swup/fragment-plugin";
import lottie from "lottie-web";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {rainbowCursor} from "cursor-effects";

import Swiper from "swiper";
import {
	Navigation,
	Pagination,
	Autoplay,
	FreeMode,
	A11y,
	EffectFade,
} from "swiper/modules";
import "swiper/swiper-bundle.min.css";

gsap.registerPlugin(ScrollTrigger);
const prefersReducedMotion = window.matchMedia(
	"(prefers-reduced-motion: reduce)"
).matches;

/* Fragment Plugin */
let fragmentPlugin = new SwupFragmentPlugin({
	rules: [
		/* From Om-oss -> Person */
		{
			from: "/:kontakt/",
			to: "/:kontakt/personal/:person/",
			containers: ["#modal"],
			name: "open-people",
		},

		/* From Person -> Om-oss */
		{
			from: "/:kontakt/personal/:person/",
			to: "/:kontakt/",
			containers: ["#modal"],
			name: "close-people",
		},

		/* From Any Page -> Childpage */
		{
			from: "/:page/",
			to: "/:page/:child_page/",
			containers: ["#modal"],
		},

		/* From Childpage -> Any Page */
		{
			from: "/:page/:child_page/",
			to: "/:page/",
			containers: ["#modal"],
		},

		/* From Home Page -> Childpage */
		{
			from: "/",
			to: "/:page/:child_page/",
			containers: ["#modal"],
		},

		/* From Childpage -> Home Page */
		{
			from: "/:page/:child_page/",
			to: "/",
			containers: ["#modal"],
		},
	],
});

/* Init Swup */

let swup = new Swup({
	animateHistoryBrowsing: true,
	plugins: [
		new SwupPreloadPlugin(),
		new SwupA11yPlugin(),
		fragmentPlugin,
		/* 		new SwupScriptsPlugin({
			head: false,
			body: false,
		}), */
	],
});

/* Swup Hooks */
swup.hooks.on("page:view", (visit) => {
	modalClose();
	overflowBody();

	if (visit.containers.includes("#swup")) {
		main();
	} else {
		modalAnimation();
		console.log("modal container");
	}

	let currentUrl = window.location.href;
	// Check if it's not the first visit (i.e., sessionStorage already has "pageVisited")
	if (sessionStorage.getItem("pageVisited")) {
		sessionStorage.setItem("lastPage", currentUrl); // Set the last visited page
	}
});

// Handle initial visit (when the page is loaded directly)
window.addEventListener("load", function () {
	sessionStorage.setItem("pageVisited", false);
	sessionStorage.removeItem("lastPage"); // Clear lastPage for the first page;
	//console.log("sessionStorage Reset -> Load");
	modalClose();
	modalAnimation();
});

/* Dialog Close */
function modalClose() {
	let dialogs = document.querySelectorAll("div#modal");
	if (dialogs.length > 0) {
		let lastPage = sessionStorage.getItem("lastPage");
		let ultraParentUrl = document
			.querySelector("main")
			.getAttribute("data-parent-url");
		let parentUrl = dialogs[0].getAttribute("data-parent-url");

		dialogs.forEach(function (dialog) {
			/* Just to prevent spamming the buttons */
			let escUsed = false;
			let clickUsed = false;

			dialog.querySelectorAll(".close").forEach(function (button) {
				// Set the button's href based on lastPage or parentUrl
				if (lastPage !== null) {
					button.href = lastPage;
				} else if (ultraParentUrl) {
					button.href = ultraParentUrl;
				} else {
					button.href = parentUrl;
				}

				button.addEventListener("click", function (event) {
					if (!clickUsed) {
						event.preventDefault();
						clickUsed = true;
					}
				});
			});

			// Add keydown event listener for 'Escape' key
			document.addEventListener("keydown", function (event) {
				if (
					event.key === "Escape" &&
					!escUsed &&
					dialog.style.display !== "none"
				) {
					var button = document.getElementById("modalClose");
					button.click();
					escUsed = true;
				}
			});

			// Reset the escUsed and clickUsed flags when modal is manually closed
			dialog.addEventListener("click", function (event) {
				if (event.target === dialog) {
					escUsed = false;
					clickUsed = false;
				}
			});
		});
	}
}

/* Cursor-effects - Draw line */
function initCursor() {
	return new rainbowCursor({
		colors: ["#ff6941"],
		length: 20,
		size: 4,
	});
}
let customCursor = initCursor();
function resetCursor() {
	if (customCursor) {
		customCursor.destroy();
	}
	customCursor = initCursor();
}

/* Custom cursor */
document.addEventListener("mousemove", function (e) {
	let mouseX = e.pageX; // + 10
	let mouseY = e.pageY - window.scrollY; // + 10
	let cursorOuter = document.querySelector(".cursorOuter");
	requestAnimationFrame(moveCursor);

	function moveCursor() {
		cursorOuter.style.transform = `translate(${mouseX}px, ${mouseY}px)`;
	}
});

/* Stop main scroll when modal is open */
function overflowBody() {
	if (document.querySelectorAll("div#modal").length > 0) {
		document.querySelector("body").classList.add("overflow-hidden");
	} else {
		document.querySelector("body").classList.remove("overflow-hidden");
	}
}
overflowBody();

/* Preloader */
function preloader() {
	function moreThanTwelveHoursOld(timestamp) {
		const twelveHours = 1 * 60 * 60 * 1000; // milliseconds in hour
		const now = new Date().getTime(); // current time in milliseconds
		return now - timestamp > twelveHours;
	}

	const firstVisitInfo = localStorage.getItem("first_visit_info");
	const visitData = firstVisitInfo ? JSON.parse(firstVisitInfo) : null;
	const preloader_element = document.getElementById("preloader");
	const lottieLogo = document.getElementById("lottie-logo");
	const lottiePath = lottieLogo.getAttribute("data-src");

	const logoAnimation = lottie.loadAnimation({
		container: lottieLogo, // the dom element that will contain the animation
		renderer: "svg",
		loop: false,
		autoplay: false,
		path: lottiePath, // the path to the animation json
	});

	if (visitData && !moreThanTwelveHoursOld(visitData.timestamp)) {
		console.log("2nd visit within 1h");

		document.addEventListener("DOMContentLoaded", function () {
			main(); // Retrigger ... Why?

			preloader_element.classList.add("fade-out");
			preloader_element.addEventListener("transitionend", function () {
				logoAnimation.destroy();
				preloader_element.remove();
			});
		});

		const newVisitData = {
			timestamp: new Date().getTime(),
		};
		localStorage.setItem("first_visit_info", JSON.stringify(newVisitData));
	} else {
		console.log("1st visit in 1h");
		document.addEventListener("DOMContentLoaded", function () {
			setTimeout(() => {
				logoAnimation.play();
				logoAnimation.addEventListener("complete", function () {
					main(); // Retrigger
					preloader_element.classList.add("fade-out");

					preloader_element.addEventListener(
						"transitionend",
						function () {
							logoAnimation.destroy();
							preloader_element.remove();
						}
					);
				});
			}, 100);
		});

		const newVisitData = {
			timestamp: new Date().getTime(),
		};
		localStorage.setItem("first_visit_info", JSON.stringify(newVisitData));
	}
}
preloader();

/* Skiplink */
document.querySelector(".skipLink").addEventListener("click", handleSkipLink);
document.querySelector(".skipLink").addEventListener("keydown", function (e) {
	if (e.key === "Enter" || e.key === " ") {
		handleSkipLink(e);
	}
});

function handleSkipLink(e) {
	var target = document.getElementById("swup");
	target.setAttribute("tabindex", "-1");
	target.focus();
}

function main() {
	resetCursor(); // reset cursor betwene pages
	ScrollTrigger.getAll().forEach((st) => st.kill());
	//swiper.destroy(true, true) // FIX target every slide function

	/* Load videos after swup transition*/
	function loadVideos() {
		const videos = document.querySelectorAll("video");
		videos.forEach((video) => {
			video.load();
		});
	}
	loadVideos();

	/* Add sizes="" value to all images for correct image selection*/
	function addImageSizes() {
		const images = document.querySelectorAll("picture img");
		images.forEach((img) => {
			const displayedWidth = img.clientWidth;
			img.setAttribute("sizes", `${displayedWidth}px`);
		});
	}
	addImageSizes();

	let resizeTimeout;
	window.addEventListener("resize", () => {
		clearTimeout(resizeTimeout);
		resizeTimeout = setTimeout(addImageSizes, 200); // Adjust timeout as needed
	});

	/* Lazyload auto videos */
	let lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));
	if ("IntersectionObserver" in window) {
		let lazyVideoObserver = new IntersectionObserver(function (
			entries,
			observer
		) {
			entries.forEach(function (video) {
				if (video.isIntersecting) {
					for (let source in video.target.children) {
						let videoSource = video.target.children[source];
						if (
							typeof videoSource.tagName === "string" &&
							videoSource.tagName === "SOURCE"
						) {
							videoSource.src = videoSource.dataset.src;
						}
					}

					video.target.load();
					video.target.classList.remove("lazy");
					lazyVideoObserver.unobserve(video.target);
				}
			});
		});

		lazyVideos.forEach(function (lazyVideo) {
			lazyVideoObserver.observe(lazyVideo);
		});
	}

	function handleLazyLoad(config = {lazy: true, timeout: 0.1}) {
		let lazyImages = gsap.utils.toArray("img[loading='lazy']");
		let imgLoaded = 0;

		const onImgLoad = () => {
			imgLoaded++;
			if (imgLoaded === lazyImages.length) {
				ScrollTrigger.refresh();
			}
		};

		lazyImages.forEach((img) => {
			if (config.lazy === false) {
				img.loading = "eager";
			}

			if (img.complete) {
				onImgLoad();
			} else {
				img.addEventListener("load", onImgLoad);
			}
		});
	}
	handleLazyLoad({lazy: true, timeout: 0.1}); // Explicit configuration

	function setupNavigationScroll() {
		const header = document.querySelector("header");
		if (!header) return;

		const marginScroll = window.innerHeight / 2;
		let lastScrollTop = 0;
		let ticking = false;

		function updateHeaderState() {
			const currentScroll = window.scrollY;
			const headerHeight = header.clientHeight;

			// Toggle scrolled class
			header.classList.toggle("scrolled", currentScroll > marginScroll);

			// Manage header transform
			if (currentScroll > marginScroll) {
				if (currentScroll > lastScrollTop) {
					// Scrolling down
					header.style.transform = `translateY(-${headerHeight}px)`;
				} else {
					// Scrolling up
					header.style.transform = "translateY(0)";
				}
			} else {
				header.style.transform = "translateY(0)";
			}

			lastScrollTop = currentScroll;
			ticking = false;
		}

		function handleScroll() {
			if (!ticking) {
				window.requestAnimationFrame(updateHeaderState);
				ticking = true;
			}
		}

		// Setup nav links focus/blur behavior
		const navLinks = header.querySelectorAll("a");
		navLinks.forEach((link) => {
			link.addEventListener("focus", () => {
				header.style.transform = "translateY(0)";
			});

			link.addEventListener("blur", () => {
				if (window.scrollY > marginScroll) {
					header.style.transform = `translateY(-${header.clientHeight}px)`;
				}
			});
		});

		// Background state management
		function updateHeaderBackground() {
			const swup = document.querySelector("#swup");
			const headerbg = swup?.getAttribute("data-headerbg");

			header.classList.toggle("nav-bg", headerbg === "true");
		}

		// Event listeners
		window.addEventListener("scroll", handleScroll);
		window.addEventListener("resize", () => {
			marginScroll = window.innerHeight / 2;
		});

		// Initial setup
		updateHeaderBackground();
	}
	setupNavigationScroll();

	/* Slider Gallery */
	let heroScrollElement = document.querySelector(".swiper-hero-gallery");
	if (heroScrollElement) {
		let slideCount = heroScrollElement.querySelectorAll(".swiper-slide");
		let slidesNum = slideCount.length;

		let swiperHeroGallery = new Swiper(".swiper-hero-gallery", {
			modules: [Autoplay, EffectFade, A11y],
			slidesPerView: 1,
			spaceBetween: 0,
			centeredSlides: true,
			loop: true,
			loopedSlides: slidesNum,
			keyboardControl: false,
			grabCursor: false,
			allowTouchMove: false,
			speed: 2000,
			effect: "fade",
			crossFade: true,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
				pauseOnMouseEnter: false,
			},
		});
	}

	/* Accordion */
	let accordionRows = document.querySelectorAll(".accordion-row");
	if (accordionRows) {
		for (let i = 0; i < accordionRows.length; i++) {
			let heading = accordionRows[i].firstElementChild;

			// Ensure the heading can receive focus
			if (heading && !heading.hasAttribute("tabindex")) {
				heading.setAttribute("tabindex", "0");

				heading.addEventListener("keydown", function (e) {
					console.log("keydown");
					if (e.key === "Enter" || e.keyCode === 13) {
						this.click();
					}
				});

				heading.addEventListener("click", function () {
					let accordionRow = this.parentElement;
					let content = accordionRow.lastElementChild;

					if (accordionRow.classList.contains("active")) {
						accordionRow.classList.remove("active");
						this.classList.remove("active"); // Remove class from heading as well
						content.style.maxHeight = null;
					} else {
						accordionRow.classList.add("active");
						this.classList.add("active"); // Add class to heading as well
						content.style.maxHeight = content.scrollHeight + "px";
					}
				});
			}
		}
	}

	const projectContainer = document.querySelector(".collection__projects");
	if (projectContainer) {
		const filterButtons = document.querySelectorAll(
			".project-filter-wrapper button"
		);
		const projects = document.querySelectorAll(".filter-item");

		filterButtons.forEach((button) => {
			button.addEventListener("click", function () {
				// Remove active class from all buttons
				filterButtons.forEach((btn) => btn.classList.remove("active"));
				// Add active class to the clicked button
				this.classList.add("active");

				// Get the category from the clicked button's data-category attribute
				const category =
					this.getAttribute("data-category").toLowerCase();

				// Add or remove the 'filtered' class based on the selected category
				if (category !== "all") {
					projectContainer.classList.add("filtered");
				} else {
					projectContainer.classList.remove("filtered");
				}

				// Filter the projects
				projects.forEach((project) => {
					// Get the multiple categories from the data-cat attribute and convert it into an array
					const projectCats = project
						.getAttribute("data-cat")
						.toLowerCase()
						.split(",");

					// Check if the selected category is in the array of categories
					if (category === "all" || projectCats.includes(category)) {
						showCard(project);
					} else {
						hideCard(project);
					}
				});
			});
		});

		// Fade out function
		function hideCard(element) {
			element.style.display = "none";
		}

		// Fade in function
		function showCard(element) {
			element.style.removeProperty("display");
		}
	}

	setTimeout(function () {
		const mainContentWrapper = gsap.utils.toArray(".content-wrapper");
		/* Footer / Content scaling */
		let footer_tl = gsap.timeline({
			scrollTrigger: {
				trigger: "footer",
				start: "top 80%",
				end: "bottom 20%",
				toggleActions: "play none none reset",
				//markers: true,
				onEnter: () => {
					gsap.to(mainContentWrapper, {
						scaleX: 0.95,
						translateY: "-5vh",
						duration: 0.8,
						ease: "power3.out",
						overwrite: "auto",
					});
				},
				onLeaveBack: () => {
					gsap.to(mainContentWrapper, {
						scaleX: 1,
						translateY: "0vh",
						duration: 0.8,
						ease: "power3.out",
						overwrite: "auto",
					});
				},
			},
		});

		/* Full width image */
		if (document.querySelector(".clip-wrapper")) {
			let full_w_img_section = gsap.utils.toArray(".clip-wrapper");

			function getInsetValue() {
				if (window.matchMedia("(max-width: 768px)").matches) {
					return "inset(0 1rem round 0.25rem)"; // Example value for max-width: 768px
				} else if (window.matchMedia("(max-width: 1600px)").matches) {
					return "inset(0 2rem round 0.25rem)"; // Example value for max-width: 1600px
				} else {
					return "inset(0 4rem round 0.25rem)"; // Default for larger screens
				}
			}

			full_w_img_section.forEach((section) => {
				let media = section.querySelector("img, video");

				gsap.to(media, {
					clipPath: getInsetValue(),
					ease: "none",
					scrollTrigger: {
						trigger: section,
						start: "75% bottom",
						end: "50%",
						scrub: 1,
						//markers: true
					},
				});

				// Update the clipPath on window resize
				window.addEventListener("resize", () => {
					gsap.to(media, {
						clipPath: getInsetValue(),
					});
				});
			});
		}

		/* Progress before/after section */
		const ProgressSection = gsap.utils.toArray(".pinned");
		if (ProgressSection) {
			ProgressSection.forEach((section) => {
				let tl = gsap.timeline({
					ease: "power1.out",
					scrollTrigger: {
						trigger: section,
						start: "95% bottom",
						end: "15% top",
						scrub: 2,
						//markers: true,
					},
				});

				tl.fromTo(
					section.querySelector(".after"),
					{xPercent: -100, x: 0},
					{xPercent: 0}
				).fromTo(
					section.querySelector(".after img"),
					{xPercent: 100, x: 0},
					{xPercent: 0},
					0
				);
			});
		}

		/* Hero offset Paralax */
		if (document.querySelector(".hero__para")) {
			/* let hero_media = gsap.utils.toArray(".hero__para > .content-media");
			let hero_text = gsap.utils.toArray(".hero__para > .content-text");

			let hero_tl = gsap.timeline({
				scrollTrigger: {
					trigger: ".hero__para",
					start: "top top",
					end: "bottom",
					scrub: 0,
					//markers: true,
				},
			});

			hero_media.forEach((media) => {
				hero_tl.to(
					media,
					{
						translateY: "50vh",
						ease: "none",
					},
					"<"
				); // The "<" ensures that all animations start at the same time
			});

			hero_text.forEach((text) => {
				hero_tl.to(
					text,
					{
						translateY: "25vh",
						ease: "none",
					},
					"<"
				);
			}); */
			gsap.timeline({
				scrollTrigger: {
					trigger: ".hero__para",
					start: "top top",
					end: "bottom",
					scrub: 0.2,
				},
			})
				.to(".hero__para > .content-media", {
					translateY: "25vh",
					ease: "none",
				})
				.to(
					".hero__para > .content-text",
					{
						translateY: "15vh",
						ease: "none",
					},
					"<"
				); // Simultaneous
		}

		/* Move image within parent element */

		moveImage();

		function moveImage() {
			let section = document.querySelector(".hoverlist");

			if (section) {
				let rows = section.querySelectorAll(".hoverlist-row");
				let mediaWrapper = section.querySelector(".hover-images");
				let images = mediaWrapper.querySelectorAll("picture");

				/* Calc mouse position */
				let lastMouseX, lastMouseY;
				section.addEventListener("mousemove", function (e) {
					const rect = section.getBoundingClientRect();
					lastMouseX = e.clientX - rect.left;
					lastMouseY = e.clientY - rect.top;
					requestAnimationFrame(moveElement);
				});

				/* move Mediawrapper */
				function moveElement() {
					mediaWrapper.style.transform = `translate(${lastMouseX}px, ${lastMouseY}px)`;
				}

				rows.forEach((row) => {
					row.addEventListener("mouseenter", function () {
						const counter = this.getAttribute("data-counter");
						mediaWrapper.classList.remove("hidden");

						images.forEach((img) => {
							if (img.getAttribute("data-counter") === counter) {
								img.classList.remove("hidden");
							} else {
								img.classList.add("hidden");
							}
						});
					});
					row.addEventListener("mouseleave", function () {
						mediaWrapper.classList.add("hidden");
					});
				});
			}
		}
	}, 100); // Delay so all content gets loaded, was breaking on some pages

	/* Slider Autosize */
	var swiper = new Swiper(".sliderAuto", {
		modules: [Pagination, Navigation, A11y, FreeMode],
		slidesPerView: "auto",
		spaceBetween: 32,
		keyboardControl: true,
		keyboard: {
			enabled: true,
		},
		grabCursor: true,
		freeMode: false,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		pagination: {
			el: ".swiper-pagination",
			type: "fraction",
		},
	});

	/* Fade in */
	const fade = gsap.utils.toArray(".content-media.fade");

	fade.forEach((fade) => {
		gsap.from(fade, {
			autoAlpha: 0,
			duration: 1,
			delay: 0,
			ease: "power4.easeOut",
			scrollTrigger: {
				trigger: fade,
				start: "top 95%",
				end: "bottom 5%",
				toggleActions: "play none play none",
			},
		});
	});
}

function modalAnimation() {
	resetCursor(); // reset cursor betwene pages
}

// Credits
if (navigator.userAgent.toLowerCase().indexOf("chrome") > -1) {
	var args = [
		"%c Design & Developed by Milk \u00A9 https://milk.se \n",
		"padding:10px 0;background: #010101;color: #ffffff;",
	];
	window.console.log.apply(console, args);
} else if (window.console) {
	window.console.log("Design & Developed by Milk :) - https://milk.se");
}
